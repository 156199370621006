export enum InputType {
  DROPDOWN = 'dropdown',
  CHECKLIST = 'checklist',
  LONG_TEXT = 'long_text',
  SHORT_TEXT = 'short_text',
  DISPLAY_LOCATION = 'display_location',
  IMAGE = 'image',
}

export enum ProgramLevelQuestionType {
  DROPDOWN = 'MultipleChoiceQuestion',
  CHECKLIST = 'MultipleChoiceMultipleAnswerQuestion',
  SHORT_TEXT = 'ShortTextQuestion',
  LONG_TEXT = 'RichTextQuestion',
}

export type ResponseOption = {
  id: number;
  optionContent: string;
  bottomOfRange: null;
  topOfRange: null;
  questionId: number;
  index: number;
  readOnly; boolean;
};

export type ProgramLevelQuestion = {
  questionList: {
    id: number;
    questionText: string;
    questionIndex: number | null;
    questionSetId: number;
    type: ProgramLevelQuestionType;
    second: number;
    childrenQuestions: null;
    numRows: null;
    numColumns: null;
    horizontalAxisLabel: null;
    verticalAxisLabel: null;
    leftLimit: null;
    rightLimit: null;
    correctAnswersCount: null;
    totalQuestionAttempts: null;
    responseOptions: ResponseOption[];
    responses: null;
    completedQuestionAttempts: null;
    isRequired: boolean;
    picture: null;
    attemptFeedbacks: null;
  }
};

export type OrgLevelQuestion = {
  profileSetting: {
    id: number;
    index: number;
    name: string;
    question: string;
    isHidden: boolean;
    isIntegrated: boolean;
    isCsvManaged: boolean;
    viewOptions: {
      icon: null;
      iconColor: null;
    };
    picture: null;
    inputType: InputType;
    selectOptions: [];
    isRequired: boolean | null;
    migratedField: boolean | null;
    isDisabled: boolean;
  }
};

export enum QuestionType {
  QUESTION = 'Question',
  PROFILE_SETTING = 'ProfileSetting',
}

export type MentorshipProgramProfileQuestion = {
  id: number;
  mentorshipProgramId: number;
  profileQuestion?: ProgramLevelQuestion | OrgLevelQuestion;
  questionType?: QuestionType;
  isRequired: boolean;
  isNovoedQuestion: boolean;
  novoedQuestionName?: string;
  isAttachedToRule: boolean;
  attachedRules?: { id: number }[];
  rulesAllowed?: boolean;
};
