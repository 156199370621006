import React from 'react';
import { css } from '@emotion/react';

import { lightBlack } from 'athena/styles/colors';
import NvTooltip from 'shared/components/nv-tooltip';
import useWindowResize from 'shared/hooks/use-window-resize';
import Avatar from 'athena/components/mentoring-program/avatar';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import ParticipantsContext from 'athena/components/mentoring-program/participants/context';
import { commonStyles } from 'athena/components/mentoring-program/participants/participant-row/styles';
import type { CellProps } from 'athena/components/mentoring-program/participants/participant-row/types';

const ConnectionsCell = (props: CellProps) => {
  const { cell, className } = props;
  const containerRef = React.useRef<HTMLTableCellElement>();
  const [fittingItems, setFittingItems] = React.useState(0);

  const { roleFilter } = React.useContext(ParticipantsContext);

  const value = cell.getValue();

  const styles = css`
    ${commonStyles};

    .avatar, .rest {
      flex-shrink: 0;
    }

    .rest {
      font-weight: 600;
      color: ${lightBlack};
    }
  `;

  const calculateFittingItems = React.useCallback(() => {
    const { clientWidth } = containerRef.current;
    const { paddingLeft, paddingRight } = window.getComputedStyle(containerRef.current);

    const getPixelsNumber = (s: string) => parseInt(s.split('px')[0], 10);

    const contentAreaWidth = clientWidth - (getPixelsNumber(paddingLeft) + getPixelsNumber(paddingRight));

    // 45 is the width of the avatar element considering its margin right
    setFittingItems(Math.floor(contentAreaWidth / 45));
  }, []);

  React.useLayoutEffect(() => {
    calculateFittingItems();
  }, [calculateFittingItems]);

  useWindowResize(() => {
    calculateFittingItems();
  }, undefined, false, [calculateFittingItems]);

  const fitAll = fittingItems >= value.length;

  const endIndex = fitAll ? value.length : fittingItems;

  const realFittingItems = value.slice(0, endIndex);
  const restItems = value.slice(endIndex, value.length);

  return (
    <div className={`d-flex align-items-center ${className}`} css={styles} ref={containerRef}>
      {realFittingItems.map((connection) => (
        <Avatar
          showNameOnHover
          user={connection.user}
          key={connection.id}
          className='mr-1 avatar'
          role={roleFilter === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR ? MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE : MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR}
        />
      ))}
      {!!restItems.length && (
        <div className='d-flex align-items-center justify-content-center rest'>
          <NvTooltip
            text={restItems.map(({ user }) => (
              <React.Fragment key={user.id}>
                {`${user.firstName} ${user.lastName}`}
                <br />
              </React.Fragment>
            ))}
          >
            <div className='text-medium'>
              +{restItems.length}
            </div>
          </NvTooltip>
        </div>
      )}
    </div>
  );
};

export default ConnectionsCell;
