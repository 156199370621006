import t from 'react-translate';

const getField = (title: string, value: string) => (
  value && (
    <>
      <div className='gray-2 text-small mb-1'>{title}</div>
      <div className='mb-1 text-regular heading-5'>{value}</div>
    </>
  )
);

const FIELDS = [
  { translationKey: 'JOB_TITLE', key: 'jobTitle' },
  { translationKey: 'GENDER', key: 'gender' },
  { translationKey: 'LANGUAGE', key: 'language' },
];

export type MoreDetailsType = {
  jobTitle?: string;
  gender?: string;
  language?: string;
};

const MoreDetails = (details: MoreDetailsType) => {
  const { jobTitle, gender, language } = details;
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.CONNECTION_DETAILS;
  const show = jobTitle || gender || language;

  return (show ? (
    <div className='px-5 py-4 info-container'>
      {FIELDS.map(({ translationKey, key }) => getField(translationBase[translationKey](), details[key]))}
    </div>
  ) : null);
};

export default MoreDetails;
