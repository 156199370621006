import t from 'react-translate';
import { useContext, useMemo } from 'react';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import NvSwitch from 'shared/components/inputs/nv-switch';
import { OrgProfileField } from 'redux/schemas/models/account-fields';
import { getInputTypeLabel } from 'athena/components/mentoring-program/constants';
import {
  InputType,
  MentorshipProgramProfileQuestion,
  OrgLevelQuestion,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { useAppDispatch } from 'redux/store';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import {
  MentorshipProgramOrgLevelQuestion,
  ProgramProfileQuestionType,
  createMentorshipProgramProfileQuestions,
  updateMentorshipProgramProfileQuestion,
} from 'redux/actions/mentoring-program-profile-questions';
import { wrapThunkAction } from 'redux/utils';

type OrgLevelFieldsProps = {
  orgLevel: OrgProfileField[];
  orgLevelQuestions: MentorshipProgramProfileQuestion[];
  reloadFields: () => void;
};

type OrgProfileFieldType = OrgProfileField & {
  isUpdate?: boolean;
  programQuestionId?: number;
};

const OrgLevelFields = ({ orgLevel, orgLevelQuestions, reloadFields }: OrgLevelFieldsProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};

  const orgLevelFields: OrgProfileFieldType[] = useMemo(() => {
    const questions = orgLevelQuestions?.map(question => {
      const orgLevelQuestion = question.profileQuestion as OrgLevelQuestion;
      if (orgLevelQuestion) {
        return {
          id: orgLevelQuestion.profileSetting.id,
          isRequired: question.isRequired,
          programQuestionId: question.id,
        };
      } return null;
    }).filter(item => item !== null);
    const items = orgLevel.filter(currentField => !currentField.isHidden).map(currentField => {
      const item = questions?.find(q => q.id === currentField.id);
      if (item) {
        return {
          ...currentField,
          isUpdate: true,
          isRequired: item.isRequired,
          programQuestionId: item.programQuestionId,
        };
      }
      return currentField;
    });
    return items;
  }, [orgLevel, orgLevelQuestions]);

  const onChange = (questionId: number, isRequired: boolean, isUpdate: boolean, programQuestionId: number) => {
    let updateList = false;
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    const mentorshipProgramProfileQuestions: MentorshipProgramOrgLevelQuestion[] = [
      {
        type: ProgramProfileQuestionType.ORG_LEVEL,
        isRequired,
        questionId,
      },
    ];
    let dispatchAction;
    if (isUpdate) {
      const [mentorshipProgramProfileQuestion] = mentorshipProgramProfileQuestions;
      dispatchAction = dispatch(updateMentorshipProgramProfileQuestion({
        mentorshipProgramId,
        mentorshipProgramProfileQuestion,
        questionId: programQuestionId,
      }));
    } else {
      updateList = true;
      dispatchAction = dispatch(
        createMentorshipProgramProfileQuestions({
          mentorshipProgramId,
          mentorshipProgramProfileQuestions,
        }),
      );
    }
    wrapThunkAction(dispatchAction)
      .then(() => {
        dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
        if (updateList) reloadFields();
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
  };

  return (
    <>
      {orgLevelFields.map(({ id, name, inputType, isCsvManaged, isIntegrated, isRequired, isUpdate = false, programQuestionId }) => (
        <div key={`org-level-${id}`} className='align-content-center field-row'>
          <div className='d-flex field-name text-regular semi-bold'>
            <span>{name}</span>
            {isRequired ? <span className='required'>*</span> : null}
          </div>
          {(isCsvManaged || isIntegrated) ? (
            <div className='d-flex align-items-center not-editable'>
              <div className='pr-1 field-type italic text-regular'>
                {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.NOT_EDITABLE.LABEL()}
              </div>
              <NvTooltip
                text={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.NOT_EDITABLE.TOOLTIP()}
              >
                <NvIcon icon='info' size='xss-smallest' />
              </NvTooltip>
            </div>
          ) : (
            <div className='field-type text-regular'>
              {getInputTypeLabel(t, inputType as unknown as InputType)}
            </div>
          )}
          {!(isCsvManaged || isIntegrated) ? (
            <NvSwitch
              className='cell'
              checked={isRequired}
              onChange={newState => onChange(id, newState, isUpdate, programQuestionId)}
            />
          ) : null}
        </div>
      ))}
    </>
  );
};

export default OrgLevelFields;
