/* eslint-disable array-callback-return */
import t from 'react-translate';
import { useContext, useEffect, useMemo } from 'react';
import {
  deleteMentorshipProgramAutoMatchingRule,
  getMentorshipProgramAutoMatchingRules,
  getMentorshipProgramAutoMatchingRulesTotalCount,
  resetAutoMatchingRulesList,
} from 'redux/actions/mentoring-program-matching-rules';
import { AutoMatchingRule } from 'redux/schemas/models/mentoring-program-matching-rules';
import { useAppDispatch } from 'redux/store';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import {
  getAutoMatchingRulesTotalCount,
  getMentorshipProgramMatchingRulesList,
  getResetAutoMatchingRulesListState,
} from 'redux/selectors/mentorship-program-matching-rules';
import { css } from '@emotion/react';
import { dividerMedium } from 'athena/styles/colors';
import { wrapThunkAction } from 'redux/utils';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { gridStyles } from './styles';
import MatchingRuleRow from './matching-rule-row';
import MentoringProgramContext from '../../context';
import { PAGE_SIZE, PageSizeParams } from '../../constants';

const styles = css`
  .headers {
    ${gridStyles};
    height: 45px;
    border-bottom: 1px solid ${dividerMedium};
  }
`;

const MatchingRulesList = () => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const resetList = useSelector(getResetAutoMatchingRulesListState);
  const autoMatchingRulesCount = useSelector(getAutoMatchingRulesTotalCount);
  const { ref: endRef, inView } = useInView();

  const headers = [
    t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.LIST.HEADERS.RULES(
      autoMatchingRulesCount,
    ),
    t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.LIST.HEADERS.IMPORTANCE_LEVEL(),
  ];

  useEffect(() => {
    dispatch(
      getMentorshipProgramAutoMatchingRulesTotalCount({
        mentorshipProgramId,
      }),
    );
  }, []);

  const params = useMemo(
    () => ({
      pageSize: PAGE_SIZE,
    }),
    [],
  );

  const { reset, result: matchingRules, loadMore } = usePaginatedApi<
  AutoMatchingRule,
  PageSizeParams
  >(
    p => {
      if (mentorshipProgramId) {
        return dispatch(
          getMentorshipProgramAutoMatchingRules({
            mentorshipProgramId,
            ...p,
          }),
        ).then(action => action.payload);
      }
      return undefined;
    },
    params,
    getMentorshipProgramMatchingRulesList,
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);

  const onDelete = (ruleId: number) => {
    wrapThunkAction(
      dispatch(
        deleteMentorshipProgramAutoMatchingRule({ mentorshipProgramId, ruleId }),
      ),
    ).then(() => {
      dispatch(
        getMentorshipProgramAutoMatchingRulesTotalCount({ mentorshipProgramId }),
      );
      reset();
    });
  };

  useEffect(() => {
    if (resetList) {
      reset();
      dispatch(resetAutoMatchingRulesList({ resetList: false }));
    }
  }, [dispatch, reset, resetList]);

  return (
    <div css={styles}>
      {matchingRules?.length ? (
        <>
          <div className='headers pl-4 pr-4 align-items-center'>
            {headers.map(header => (
              <div key={header} className='text-small semi-bold'>
                {header}
              </div>
            ))}
          </div>
          {matchingRules?.map((rule: AutoMatchingRule) => {
            if (rule) {
              return (
                <MatchingRuleRow
                  key={rule.id}
                  rule={rule}
                  onDelete={onDelete}
                />
              );
            }
            return null;
          })}
          <div ref={endRef} />
        </>
      ) : null}
    </div>
  );
};

export default MatchingRulesList;
