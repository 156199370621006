import { createReducer } from '@reduxjs/toolkit';
import { getMentorshipProgramProfileAnswers } from 'redux/actions/mentoring-program-profile-answers';
import { normalize } from 'normalizr';
import { MentorshipProgramProfileAnswersSchema } from 'redux/schemas/api/mentoring-program-profile-answers';
import { replaceArrays } from 'shared/lodash-utils';
import { mergeWith } from 'lodash';
import { initialRootState } from '.';

const setProgramProfileAnswersActionHandler = (state, action) => {
  const normalized = normalize(
    action.payload,
    MentorshipProgramProfileAnswersSchema,
  );

  mergeWith(state.models, normalized.entities, replaceArrays);
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(
      getMentorshipProgramProfileAnswers.fulfilled,
      setProgramProfileAnswersActionHandler,
    );
});
