import * as yup from 'yup';
import t from 'react-translate';
import { css } from '@emotion/react';
import { dividerMedium } from 'athena/styles/colors';
import {
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import AthenaTextInput from 'athena/components/text-input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NvSelect from 'shared/components/inputs/nv-select';
import {
  QUESTION_MAX_LENGTH,
  getProgramLevelInputTypeLabelsMap,
} from 'athena/components/mentoring-program/constants';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  MentorshipProgramProfileQuestion,
  ProgramLevelQuestion,
  ProgramLevelQuestionType,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import NvSwitch from 'shared/components/inputs/nv-switch';
import CollapsibleRowMenu, {
  CollapsibleRowMenuButton,
  CollapsibleRowMenuDivider,
} from 'components/collapsible-row-menu';
import { useAppDispatch } from 'redux/store';
import {
  NewProgramLevelQuestionDraft,
  ProgramProfileQuestionType,
  createMentorshipProgramProfileQuestions,
  deleteMentorshipProgramProfileQuestion,
  deleteProgramLevelQuestionDraft,
  updateMentorshipProgramProfileQuestion,
} from 'redux/actions/mentoring-program-profile-questions';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { isEmpty } from 'underscore';
import { wrapThunkAction } from 'redux/utils';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { config } from '@config/pendo.config.json';
import NvIcon from 'shared/components/nv-icon';
import { danger } from 'styles/global_defaults/colors';
import NvTooltip from 'shared/components/nv-tooltip';
import { getSanitizedStyles } from 'shared/utils';
import InlineConfirmation from 'shared/components/inline-confirmation';
import NvPopover from 'shared/components/nv-popover';
import NvConfirmationPopover from 'shared/components/nv-confirmation-popover';

const overlayStyles = css`
  max-width: 280px;
  .confirmation {
    max-width: 100%;
    .button-container {
      justify-content: center;
    }
  }
`;

type ProgramLevelFieldProps = {
  index: number;
  question: MentorshipProgramProfileQuestion | NewProgramLevelQuestionDraft;
  canMoveUp: boolean;
  onMoveUp: () => void;
  canMoveDown: boolean;
  onMoveDown: () => void;
  onAddOrRemove: () => void;
};

const styles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 ${standardSpacing}px;
  border-bottom: 1px solid ${dividerMedium};

  .required-fields {
    height: 100%;
    display: grid;
    gap: ${threeQuartersSpacing}px;
    grid-template-columns: auto 30% 10% 16px;

    .select-type {
      div {
        width: 222px;
        .text-medium {
          border: 1px solid ${dividerMedium};
        }
      }
      .select-menu {
        div {
          width: 220px;
        }
      }
    }
    .required-type {
      > div:first-of-type {
        border: 1px solid ${danger};
      }
    }

    .self-centered {
      justify-self: center;
    }
  }
`;

const ProgramLevelField = forwardRef<any, ProgramLevelFieldProps>(({
  index,
  question,
  canMoveUp,
  onMoveUp,
  canMoveDown,
  onMoveDown,
  onAddOrRemove,
}, ref) => {
  const dispatch = useAppDispatch();
  const [questionId, setQuestionId] = useState(question.id);
  const [type, setType] = useState(ProgramProfileQuestionType.PROGRAM_LEVEL);
  const [isRequired, setIsRequired] = useState(question.isRequired || false);
  const [questionType, setQuestionType] = useState<ProgramLevelQuestionType>();
  const [questionText, setQuestionText] = useState<string>();
  const [responseOptions, setResponseOptions] = useState<string>();
  const [error, setError] = useState<string>();
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
  const [isSwitchingRequired, setIsSwitchingRequired] = useState(false);

  const selectRef = useRef<{ toggle: HTMLDivElement; container: HTMLDivElement; }>();
  const rowMenuRef = useRef<HTMLDivElement>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const responseOptionsRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const { profileQuestion } = question;
    const { questionList } = (profileQuestion as ProgramLevelQuestion) || {};

    setQuestionId(question.id);
    setIsRequired(question.isRequired);
    setQuestionType(questionList?.type);
    setQuestionText(questionList?.questionText);
    setResponseOptions(
      questionList?.responseOptions?.map(elem => elem.optionContent).join(', '),
    );
  }, [question]);

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};

  const getIsDropdownOrChecklist = (value: ProgramLevelQuestionType) => [
    ProgramLevelQuestionType.DROPDOWN,
    ProgramLevelQuestionType.CHECKLIST,
  ].includes(value);

  const isDropdownOrChecklist = getIsDropdownOrChecklist(questionType);

  const validationSchema = yup.object().shape({
    isRequired: yup.boolean().required(),
    questionText: yup
      .string()
      .required(t.VALIDATION.REQUIRED())
      .max(
        QUESTION_MAX_LENGTH,
        t.VALIDATION.MAX_LENGTH(`${QUESTION_MAX_LENGTH}`),
      ),
    questionType: yup.string().required(t.VALIDATION.REQUIRED()),
    responseOptions: yup.string().when('questionType', {
      is: () => isDropdownOrChecklist,
      then: yup
        .string()
        .required(t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.VALIDATION.REQUIRED_RESPONSE_OPTIONS()),
      otherwise: yup.string(),
    }),
  });
  const inputTypeSelectOptions = Object.entries(
    getProgramLevelInputTypeLabelsMap(t),
  ).map(([key, value]) => ({ label: value, value: key }));

  const methods = useForm({
    mode: 'onBlur',
    shouldUnregister: true,
    defaultValues: useMemo(
      () => ({
        questionId,
        type,
        isRequired,
        questionType,
        questionText,
        responseOptions,
      }),
      [
        questionId,
        type,
        isRequired,
        questionType,
        questionText,
        responseOptions,
      ],
    ),
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    setValue,
    formState: { isDirty, errors },
  } = methods;

  const isQuestionTypeDropdownOpen = () => {
    const container = selectRef?.current?.container;
    const selectMenu = container?.querySelector('.select-menu');
    return !!selectMenu;
  };

  const dynamicStyles = css`${showConfirmationDelete ? 'min-height: 100px;' : ''}`;

  useEffect(() => {
    reset({
      questionId,
      type,
      isRequired,
      questionType,
      questionText,
      responseOptions,
    });
  }, [
    questionId,
    type,
    isRequired,
    questionType,
    questionText,
    responseOptions,
    reset,
  ]);

  useEffect(() => {
    const areResponseOptionsValid = isDropdownOrChecklist
      ? !!responseOptions.trim()
      : true;
    if (isDirty && isEmpty(errors) && areResponseOptionsValid) {
      const mentorshipProgramProfileQuestion = {
        index,
        isRequired,
        questionText,
        questionType,
        type,
        responseOptions:
          isDropdownOrChecklist && responseOptions
            ? responseOptions
              .split(',')
              .map((val: string) => ({ optionContent: val.trim() }))
            : [],
      };
      let dispatchAction;
      if (!questionId) {
        const draftId = (question as NewProgramLevelQuestionDraft).tempId;
        if (draftId && questionText && questionType) {
          dispatchAction = dispatch(
            createMentorshipProgramProfileQuestions({
              draftId,
              mentorshipProgramId,
              mentorshipProgramProfileQuestions: [
                mentorshipProgramProfileQuestion,
              ],
            }),
          );
        }
      } else {
        dispatchAction = dispatch(
          updateMentorshipProgramProfileQuestion({
            questionId,
            mentorshipProgramId,
            mentorshipProgramProfileQuestion,
          }),
        );
      }
      if (dispatchAction) {
        dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
        wrapThunkAction(dispatchAction)
          .then(() => {
            dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
            setUnsavedChanges(false);
            onAddOrRemove();
          })
          .finally(() => {
            setTimeout(() => {
              dispatch(setSaveStatus({ newStatus: null }));
            }, 3000);
          });
      }
    } else if (isDirty || !isEmpty(errors) || !areResponseOptionsValid) {
      setUnsavedChanges(true);
    }
  }, [questionText, questionType, isRequired, responseOptions]);

  useEffect(() => {
    if (!responseOptions && isDropdownOrChecklist) {
      responseOptionsRef?.current?.focus();
    }
  }, [isRequired]);

  const onDelete = () => {
    if (questionId) {
      dispatch(
        deleteMentorshipProgramProfileQuestion({
          mentorshipProgramId,
          questionId,
        }),
      );
      onAddOrRemove();
    } else {
      const { tempId } = question as NewProgramLevelQuestionDraft;
      dispatch(deleteProgramLevelQuestionDraft({ tempId }));
    }
    setShowConfirmationDelete(false);
  };

  const handleDelete = () => {
    if (question?.isAttachedToRule) {
      setShowConfirmationDelete(true);
    } else onDelete();
  };

  const swapRowMenu = (action: string) => {
    const [callback, siblingProp] = action === 'move-up'
      ? [onMoveUp, 'previousSibling']
      : [onMoveDown, 'nextSibling'];

    const currentTrigger = rowMenuRef.current.querySelector('.icon-in-progress')?.parentElement;
    const sibling = rowMenuRef.current.closest('form')[siblingProp] as HTMLFormElement;
    const siblingTrigger = sibling.querySelector('.icon-in-progress')?.parentElement;

    callback();
    // Collapse the current row menu
    currentTrigger?.click();
    // Expand the row menu of the new position
    siblingTrigger?.click();
  };

  useImperativeHandle(ref, () => ({
    unsavedChanges: () => unsavedChanges,
  }));

  const hardSetIsRequired = (value: any) => {
    setIsSwitchingRequired(value);
    setValue('isRequired', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setIsRequired(value);
  };

  const handleRequiredChange = (newState: boolean) => {
    if (!newState) hardSetIsRequired(true);
    else setIsRequired(newState);
  };

  return (
    <FormProvider {...methods}>
      <form css={getSanitizedStyles([styles, dynamicStyles])}>
        <InlineConfirmation
          show={showConfirmationDelete}
          title={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INLINE_CONFIRMATION.TITLE()}
          description={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INLINE_CONFIRMATION.DESCRIPTION()}
          cancelText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INLINE_CONFIRMATION.CANCEL()}
          confirmText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INLINE_CONFIRMATION.DELETE()}
          onCancel={() => setShowConfirmationDelete(false)}
          onConfirm={onDelete}
        >
          <>
            <div className='required-fields py-2'>
              <AthenaTextInput
                required
                withForm
                value={questionText}
                showLabel={false}
                name='questionText'
                className='align-content-center'
                onBlur={({ target: { value } }) => {
                  setQuestionText(value);
                  setTimeout(() => {
                    if (!questionType && !isQuestionTypeDropdownOpen()) {
                      selectRef.current?.toggle.click();
                    }
                  }, 200);
                }}
                placeholder={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.PLACEHOLDERS.QUESTION_TEXT()}
              />
              <NvSelect
                ref={selectRef}
                value={questionType}
                className={`select-type align-items-center ${(error && !questionType) ? 'required-type' : ''}`}
                options={inputTypeSelectOptions}
                onChange={value => {
                  setValue('questionType', value, {
                    shouldValidate: true,
                  });
                  setQuestionType(value);
                  if (getIsDropdownOrChecklist(value)) {
                    setTimeout(() => {
                      responseOptionsRef?.current?.focus();
                    });
                  }
                  setResponseOptions(isDropdownOrChecklist ? responseOptions : '');
                }}
                onBlur={() => {
                  if (!questionType) {
                    setError(t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.VALIDATION.REQUIRED_QUESTION_TYPE());
                  } else {
                    setError(null);
                  }
                }}
                placeholder={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.PLACEHOLDERS.QUESTION_TYPE()}
                data-qa={config.pendo.athena.mentorshipProgram.settings.programProfileSetup.updateProgramLevelProfileQuestion}
              />
              <NvPopover
                enabled
                show={isSwitchingRequired}
                overlayStyles={overlayStyles}
                content={(
                  <NvConfirmationPopover
                    onCancel={() => setIsSwitchingRequired(false)}
                    onConfirm={() => hardSetIsRequired(false)}
                    header={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.TITLE()}
                    message={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.DESCRIPTION()}
                    cancelBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.CANCEL()}
                    confirmBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.OK()}
                  />
                )}
                placement='top'
                rootClose
                onHide={() => setIsSwitchingRequired(false)}
                className='d-inline-flex mw-100'
                preventOverflow
              >
                <NvSwitch
                  withForm
                  name='isRequired'
                  checked={isRequired}
                  className='self-centered align-content-center'
                  onChange={handleRequiredChange}
                  data-qa={config.pendo.athena.mentorshipProgram.settings.programProfileSetup.updateProgramLevelProfileQuestion}
                />
              </NvPopover>
              <CollapsibleRowMenu className='self-centered' ref={rowMenuRef}>
                <>
                  <CollapsibleRowMenuButton
                    icon='trash'
                    onClick={handleDelete}
                    data-qa={
                      config.pendo.athena.mentorshipProgram.settings
                        .programProfileSetup.deleteProgramLevelProfileQuestion
                    }
                  />
                  <CollapsibleRowMenuDivider />
                  <NvTooltip
                    text={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.DISABLED_STATE.MOVE_UP_DOWN()}
                    enabled={!canMoveDown && !canMoveUp}
                    placement='top'
                    maxWidth={240}
                  >
                    <div className='d-flex'>
                      <CollapsibleRowMenuButton
                        icon='move-up'
                        onClick={() => {
                          swapRowMenu('move-up');
                        }}
                        disabled={!canMoveUp}
                        data-qa={config.pendo.athena.mentorshipProgram.settings.programProfileSetup.updateProgramLevelProfileQuestion}
                      />
                      <CollapsibleRowMenuButton
                        icon='move-down'
                        onClick={() => {
                          swapRowMenu('move-down');
                        }}
                        disabled={!canMoveDown}
                        data-qa={config.pendo.athena.mentorshipProgram.settings.programProfileSetup.updateProgramLevelProfileQuestion}
                      />
                    </div>
                  </NvTooltip>
                  <CollapsibleRowMenuDivider />
                </>
              </CollapsibleRowMenu>
            </div>
            {error && !questionType && (
              <div className='d-flex mb-2'>
                <NvIcon icon='warning' size='small' className='text-danger mr-1' />
                <div className='text-danger'>{error}</div>
              </div>
            )}
            <div>
              {isDropdownOrChecklist ? (
                <>
                  <AthenaTextInput
                    required
                    withForm
                    ref={responseOptionsRef}
                    value={responseOptions}
                    showLabel={false}
                    name='responseOptions'
                    className='pb-2'
                    onBlur={({ target: { value } }) => setResponseOptions(value)}
                    placeholder={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.PLACEHOLDERS.RESPONSE_OPTIONS()}
                  />
                  {errors?.responseOptions && (
                    <div className='d-flex mb-2'>
                      <NvIcon icon='warning' size='small' className='text-danger mr-1' />
                      <div className='text-danger'>{errors.responseOptions.message}</div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </>
        </InlineConfirmation>
      </form>
    </FormProvider>
  );
});

export default ProgramLevelField;
