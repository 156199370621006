/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import t from 'react-translate';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import {
  CompletionStatus,
  MentoringProgramEnrollment,
  StepKey,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { getMentoringProgramEnrollment as getMentoringProgramEnrollmentSelector } from 'redux/selectors/mentoring-program-enrollments';
import { css } from '@emotion/react';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { gray6 } from 'styles/global_defaults/colors';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { createMentorshipProgramProfileAnswers } from 'redux/actions/mentoring-program-profile-answers';
import { setMentoringProfileReviewed } from 'redux/actions/mentoring-program-enrollments';
import { useInView } from 'react-intersection-observer';
import { isEmpty } from 'underscore';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { setProfilePicture, updateBasicUserProfile } from 'redux/actions/users';
import { AccountField } from 'athena/components/mentoring-program/constants';
import { AngularContext } from 'react-app';
import {
  areAllRequiredFieldsAnswered,
  isAnyRequiredFieldUnanswered,
} from '../utils';
import ProgramQuestions from './questions/program-questions';
import OrgQuestions from './questions/org-questions';
import RequiredQuestionsModal from '../../required-questions-modal';

const modalStyles = css`
  &.bs4-modal {
    .bs4-modal-dialog {
      .bs4-modal-body {
        padding: 0;
        overflow: hidden;
      }
    }
  }
`;
const styles = css`
  .profile-content {
    min-height: 654px;
    max-height: 654px;
    overflow-y: auto;
    padding: ${halfSpacing}px 167px;
  }

  .profile-footer {
    height: 105px;
    border-top: 1px solid ${gray6};
  }
`;

type ProfileModalProps = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

const ProfileModal = ({ title, showModal, setShowModal }: ProfileModalProps) => {
  const dispatch = useAppDispatch();
  const { injectServices } = useContext(AngularContext);
  const [$state, CurrentUserManager] = injectServices(['$state', 'CurrentUserManager']);
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId, mentorshipProgramEnrollment: enrollmentId } = mentoringProgram || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(
    (state) => getMentoringProgramEnrollmentSelector(state, enrollmentId),
  );
  const { user, states } = mentorshipProgramEnrollment || {};
  const questions = [OrgQuestions, ProgramQuestions];
  const { ref: profileEndRef, inView: hasProfileBeenViewed } = useInView();
  const [remainingQuestions, setRemainingQuestions] = useState(0);
  const [showRequiredQuestionsModal, setShowRequiredQuestionsModal] = useState(false);
  const methods = useForm();
  const { handleSubmit, getValues, reset } = methods;
  const [initialData, setInitialData] = useState<string>();

  useEffect(() => {
    setTimeout(() => {
      setInitialData(JSON.stringify(getValues()));
    }, 300);
  }, []);

  const setProfileReviewCompleted = () => dispatch(
    setMentoringProfileReviewed({
      programId: mentorshipProgramId,
      enrollmentId,
    }),
  );
  useEffect(() => {
    if (hasProfileBeenViewed) {
      if (areAllRequiredFieldsAnswered(states)) {
        setProfileReviewCompleted();
      }
    }
  }, [hasProfileBeenViewed]);

  const getModalTitle = () => {
    let modalTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.TITLE.DEFAULT();
    if (states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.COMPLETED) {
      modalTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.TITLE.EDIT_PROFILE();
    } else if (isAnyRequiredFieldUnanswered(states)) {
      modalTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.TITLE.COMPLETE_PROFILE();
    } else {
      modalTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.TITLE.REVIEW_PROFILE();
    }
    return modalTitle;
  };

  type QuestionAnswerType = {
    name: string;
    isRequired: boolean;
    mentorshipProgramProfileQuestionId: number;
    answer: string | string[] | null;
  };

  const onSubmit = (data: {
    [key: number]: QuestionAnswerType;
    firstName?: string;
    lastName?: string;
  }) => {
    if (!isEmpty(data)) {
      const { firstName, lastName } = data;
      if (firstName || lastName) {
        dispatch(updateBasicUserProfile({
          user: {
            firstName: firstName || user.firstName,
            lastName: lastName || user.lastName,
          },
        }));
        delete data.firstName;
        delete data.lastName;
      }
      const dataValues = Object.values(data) as QuestionAnswerType[];
      const remaining = dataValues.filter(
        ({ isRequired, answer }) => isRequired && (answer === '' || answer === null || answer.length === 0),
      ).length;
      const profileFieldIndex = dataValues.findIndex(question => question.name === AccountField.PICTURE_FILE_NAME);
      if (profileFieldIndex !== -1) {
        const profileField = dataValues[profileFieldIndex];
        if (profileField.answer instanceof File) {
          dispatch(
            setProfilePicture({ image: profileField.answer, userId: user.id }),
          ).then((action) => CurrentUserManager.user.updateFromReact({
            profilePicture: action.payload,
            profilePictureUrl: action.payload,
          }));
          dataValues.splice(profileFieldIndex, 1);
        }
      }
      wrapThunkAction(
        dispatch(
          createMentorshipProgramProfileAnswers({
            mentorshipProgramId,
            mentorshipProgramProfileAnswers: dataValues.map(
              questionAnswered => ({
                ...questionAnswered,
                mentorshipProgramEnrollmentId: enrollmentId,
              }),
            ),
            enrollmentId,
          }),
        ),
      )
        .then(() => {
          if (
            remaining === 0 && states[StepKey.PROFILE_REVIEW].status !== CompletionStatus.COMPLETED
          ) {
            setProfileReviewCompleted();
          }
          resetShowProfileParam();
          setShowModal(false);
        })
        .then(() => {
          setRemainingQuestions(remaining);
          setShowRequiredQuestionsModal(true);
        });
    }
  };

  const resetShowProfileParam = () => {
    const params = { showProfile: false };
    $state.go($state.current, params, { notify: false });
  };

  const onCloseModal = () => {
    const newData = JSON.stringify(getValues());
    if (initialData !== newData) {
      dispatch(
        openConfirmationDialog({
          title: t.FORM.UNSAVED_CHANGES.ARE_YOU_SURE(),
          bodyText:
            t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.UNSAVED_CHANGES(),
          confirmText: t.FORM.YES_SURE(),
          onConfirm: () => setShowModal(false),
        }),
      );
    } else {
      setShowModal(false);
    }
    resetShowProfileParam();
    reset();
  };

  return (
    <>
      <NvModal
        width={960}
        height='unset'
        type={ModalType.FIXED}
        header={title || getModalTitle()}
        body={(
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div css={styles}>
                <div className='profile-content position-relative'>
                  <div className='heading-4'>
                    {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.SUBTITLE()}
                  </div>
                  <div className='text-regular pt-2 pb-5'>
                    {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.DESCRIPTION()}
                  </div>
                  {questions.map((Questions, index) => (
                    <Questions
                      key={index}
                      enrollmentId={enrollmentId}
                      mentorshipProgramId={mentorshipProgramId}
                    />
                  ))}
                </div>
                <div ref={profileEndRef} />
                <div className='d-flex align-items-center justify-content-center profile-footer'>
                  <Button type='submit'>
                    {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.SAVE()}
                  </Button>
                </div>
              </div>
            </form>
          </FormProvider>
        )}
        show={showModal}
        modalStyles={modalStyles}
        onClose={onCloseModal}
      />
      <RequiredQuestionsModal
        show={showRequiredQuestionsModal}
        remainingQuestions={remainingQuestions}
        onClose={() => setShowRequiredQuestionsModal(false)}
      />
    </>
  );
};

export default ProfileModal;
