/* eslint-disable react/no-array-index-key */
import t from 'react-translate';
import { getInputType, getInputTypeLabel, getName } from 'athena/components/mentoring-program/constants';
import { MentorshipProgramProfileQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';
import NvSwitch from 'shared/components/inputs/nv-switch';
import { useAppDispatch } from 'redux/store';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { wrapThunkAction } from 'redux/utils';
import { updateMentorshipProgramProfileQuestion } from 'redux/actions/mentoring-program-profile-questions';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { useContext } from 'react';

type AccountLevelFieldsProps = {
  accountLevel: MentorshipProgramProfileQuestion[];
};

const AccountLevelFields = ({ accountLevel }: AccountLevelFieldsProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};

  const onChange = (questionId: number, newState: boolean) => {
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    const mentorshipProgramProfileQuestion = {
      isRequired: newState,
    };
    wrapThunkAction(dispatch(updateMentorshipProgramProfileQuestion({
      mentorshipProgramId,
      mentorshipProgramProfileQuestion,
      questionId,
    }))).then(() => {
      dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
    })
      .finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
  };

  return (
    <>
      {accountLevel.map(({ id, novoedQuestionName, isRequired }) => (
        <div
          key={`account-level-${id}`}
          className='align-content-center field-row'
        >
          <div className='field-name text-regular semi-bold'>
            {getName(novoedQuestionName, t)}
            {isRequired ? <span className='required'>*</span> : null}
          </div>
          <div className='field-type text-regular'>
            {getInputTypeLabel(t, getInputType(novoedQuestionName))}
          </div>
          <NvSwitch
            className='cell'
            checked={isRequired}
            onChange={newState => onChange(id, newState)}
          />
        </div>
      ))}
    </>
  );
};

export default AccountLevelFields;
