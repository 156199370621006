import { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import moment from 'moment';
import prodPathReplace from 'shared/prod-path-rewrite';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

// Styles
import { css } from '@emotion/react';
import { almostBlack, novoEdBlue } from 'athena/styles/colors';

// Components
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvIcon from 'shared/components/nv-icon';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { getSettingsSaveStatus } from 'redux/selectors/mentoring-programs';

import MentoringProgramContext from '../../context';

const SHORT_DATE_FORMAT = 'MMMM D';
const FULL_DATE_FORMAT = 'MMMM D, YYYY [at] h:mm A';
const INPUT_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

const styles = css`
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;

  .description {
    color: ${almostBlack};
  }

  .match-announcement-date > div, .react-datepicker-wrapper {
    width: 100%;
  }

  .match-announcement-icon {
    color: ${novoEdBlue};
  }

  .note-container {
    background-color: #F3F8FE;
  }

  .match-announcement-image {
    img {
      border-radius: 12px;
    }
  }
`;

type DatePickerSectionType = {
  name: string;
  title: string;
  description: string;
  placeholder: string;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  onSubmit?: () => void;
};

const DatePickerSection = ({
  name,
  title,
  description,
  placeholder,
  minDate,
  maxDate,
  onSubmit,
}: DatePickerSectionType) => {
  const { setValue } = useFormContext();
  const [dateValue, setDateValue] = useState(null);
  const saveStatus = useSelector(getSettingsSaveStatus);

  const handleChange = (date) => {
    setTimeout(() => {
      setDateValue(date);
    });
  };

  useEffect(() => {
    if (saveStatus !== SaveStatus.IN_PROGRESS) {
      if (dateValue) {
        dateValue.toString = () => dateValue.format(INPUT_DATE_FORMAT);
      }
      setValue(name, dateValue);
      onSubmit();
    }
  }, [dateValue]);

  return (
    <div className='d-flex w-100 mb-2 col'>
      <div className='w-50 col'>
        <div className='d-flex w-100 mb-2 heading-5'>{title}</div>
        <div className='d-flex w-100'>{description}</div>
      </div>
      <div className='d-flex w-50 align-items-center pl-4 match-announcement-date'>
        <NvDatePicker
          withForm
          required
          min={minDate}
          max={maxDate}
          name={name}
          type={DatePickerType.DATETIME}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

type NoteType = {
  translationBase: any;
};

const Note = ({ translationBase }: NoteType) => (
  <div className='d-flex w-100 note-container p-3 col align-items-center'>
    <NvIcon icon='manual-connection' size='small' className='mr-2 match-announcement-icon' />
    <div className='mr-2 gray-1'>
      {translationBase.NOTE_NO_DATE()}
    </div>
    <div className='match-announcement-image'>
      <img
        src={prodPathReplace('images/match-announcement-date.gif')}
        alt={translationBase.TITLE()}
      />
    </div>
  </div>
);

const MatchAnnouncementDate = ({ onSubmit }) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { releasedDate, endDate } = mentoringProgram;
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_SETTINGS.MATCH_ANNOUNCEMENT_DATE;

  return (
    <div css={styles} className='mb-5'>
      <DatePickerSection
        name='matchAnnouncementDate'
        title={translationBase.TITLE()}
        description={translationBase.DESCRIPTION()}
        placeholder={translationBase.PLACEHOLDER()}
        minDate={moment(releasedDate)}
        maxDate={moment(endDate)}
        onSubmit={onSubmit}
      />
      <Note translationBase={translationBase} />
    </div>
  );
};

export default MatchAnnouncementDate;
