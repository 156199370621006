import t from 'react-translate';
import { useCallback, useContext, useEffect, useMemo } from 'react';

// Redux
import { useAppDispatch } from 'redux/store';
import { setSaveStatus, updateMentoringProgram } from 'redux/actions/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { wrapThunkAction } from 'redux/utils';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Styles
import { css } from '@emotion/react';
import { almostBlack } from 'athena/styles/colors';

// Components
import MatchAnnouncementDate from './match-announcement-date';
import MatchingRules from './matching-rules';

import MentoringProgramContext from '../../context';

const styles = css`
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;

  .description {
    color: ${almostBlack};
  }
`;

const MatchingSettings = () => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const {
    id,
    endDate,
    releasedDate,
    matchAnnouncementDate,
  } = mentoringProgram || {};

  const validationSchema = yup.object().shape({
    matchAnnouncementDate: yup.string().nullable().required(),
  });

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      matchAnnouncementDate: null,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (mentoringProgram) {
      reset({
        matchAnnouncementDate: mentoringProgram.matchAnnouncementDate || null,
      });
    }
  }, [mentoringProgram, reset]);

  const onSubmit = (data) => {
    const matchAnnouncementDateISO = new Date(data.matchAnnouncementDate)?.toISOString();
    const dataAnnouncementDateISO = new Date(matchAnnouncementDate)?.toISOString();
    if (matchAnnouncementDateISO !== dataAnnouncementDateISO) {
      dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
      wrapThunkAction(dispatch(updateMentoringProgram({ id, ...data }))).then(() => {
        dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
      }).finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
    }
  };

  return (
    <div css={styles}>
      <div className='pb-4 mb-8'>
        <div className='title heading-3 mb-6'>
          {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_SETTINGS.TITLE()}
        </div>
        <FormProvider {...methods}>
          <form onChange={handleSubmit(onSubmit)}>
            {releasedDate && endDate && <MatchAnnouncementDate onSubmit={handleSubmit(onSubmit)} />}
          </form>
        </FormProvider>
        <MatchingRules />
      </div>
    </div>
  );
};

export default MatchingSettings;
