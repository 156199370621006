import { css } from '@emotion/react';
import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import MenteeCard from 'athena/components/mentoring-program/home/participant/mentee-card';
import MentoringProgramContext, { MentoringProgramHomeUIContext } from 'athena/components/mentoring-program/context';
import ProgramHeader from 'athena/components/program-header';
import { almostWhite } from 'athena/styles/colors';
import { white } from 'styles/global_defaults/colors';
import { standardSpacing, largeSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import { withOverflowFlexGrowClass } from './constants';


const MyMenteesList = () => {
  const [innerScroll, setInnerScroll] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>();
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { mentorshipProgramEnrollment: enrollmentId, programAlias } = mentoringProgram || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(state => getMentoringProgramEnrollment(state, enrollmentId));
  const { assignedConnection } = mentorshipProgramEnrollment || {};

  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, mentoringProgram?.id));

  const styles = css`
    flex: 1;
    overflow: hidden;
    ${innerScroll === 0 && css`
      overflow-y: auto;
    `};

    .scroll-content {
      height: calc(100vh + 200px);
      background-color: ${almostWhite};
    }

    .mentee-list {
      background-color: ${white};
      width: fit-content;
      margin-left: ${standardSpacing}px;
      padding: ${largeSpacing}px;
      transform: translateY(-90px);
      border-radius: ${halfSpacing}px;
      box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 10%);
    }
  `;

  const uiContextValue = {
    withOverflowFlexGrowClass,
  };

  return (
    <MentoringProgramHomeUIContext.Provider value={uiContextValue}>
      <div
        css={styles}
        ref={scrollContainerRef}
        onScroll={() => {
          const { clientHeight, scrollHeight, scrollTop } = scrollContainerRef.current;

          const total = scrollHeight - clientHeight;

          setScrollPercentage((100 / total) * scrollTop);
        }}
      >
        <div
          className='scroll-content'
        >
          <ProgramHeader scrollPercentage={scrollPercentage} />
          <div className='mentee-list'>
            <div className='heading-3' onScroll={(e) => setInnerScroll(e.currentTarget.scrollTop)}>{t.MENTORING_PROGRAMS.MY_MENTEES(mentoringProgramAliases)}</div>
            {assignedConnection?.map((connection) => (
              <MenteeCard
                id={connection.id}
                programId={mentoringProgram.id}
                user={connection.user}
              />
            ))}
          </div>
        </div>
      </div>
    </MentoringProgramHomeUIContext.Provider>
  );
};

export default MyMenteesList;
