import { DRAFT_PREFIX } from 'athena/components/mentoring-program/constants';
import { RootState } from 'redux/schemas';
import { MentorshipProgramProfileQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';
import { createSelector } from 'reselect';

export const getMentorshipProgramProfileQuestions = (state: RootState) => state.models.mentorshipProgramProfileQuestions;

export const getMentorshipProgramProfileQuestionsList = createSelector(
  (state: RootState) => state.models.mentorshipProgramProfileQuestions,
  (state, ids: number[]) => ids,
  (mentorshipProgramProfileQuestions: Record<number, MentorshipProgramProfileQuestion>, ids: number[]) => ids.map((id) => mentorshipProgramProfileQuestions[id]),
);

export const getMentorshipProgramProfileDraftQuestions = (state: RootState) => Object.entries(state.models.mentorshipProgramProfileQuestions).filter(([key, value]) => key.includes(DRAFT_PREFIX)).reduce((items, currentItem) => {
  items.push(currentItem[1]);
  return items;
}, []);

export const isLoadingProgramProfile = (state: RootState) => state.app.mentoringProgram.settings.profile.isLoading;
