import React from 'react';
import uuid from 'react-uuid';
import t from 'react-translate';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { almostWhite } from 'athena/styles/colors';
import NumberInput from 'athena/components/number-input';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { css } from '@emotion/react';

const MIN_MENTEES = 1;
// TODO: Change if necessary
const MAX_MENTEES = Infinity;

type MaxMenteesSettingProps = {
  name: string;
};

const MaxMenteesSetting = React.memo(
  (props: MaxMenteesSettingProps) => {
    const { name } = props;
    const id = React.useMemo(() => `target-${uuid()}`, []);
    const ref = React.useRef<HTMLDivElement>();
    const { mentoringProgram } = React.useContext(MentoringProgramContext);
    const [targetElementRef, setTargetElementRef] = React.useState<
    HTMLSpanElement
    >();
    const mentoringProgramAliases = useSelector(state => getMentoringProgramAliases(state, mentoringProgram.id));

    React.useLayoutEffect(() => {
      const foundTarget: HTMLSpanElement = ref.current.querySelector(`#${id}`);
      setTargetElementRef(foundTarget);
    }, [id]);

    const styles = css`
      background-color: ${almostWhite};
      border-radius: ${quarterSpacing}px;
    `;

    return (
      <div className='pl-4 pr-4 pt-2 pb-2' css={styles} ref={ref}>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.CONNECTIONS({
          inputId: id,
          ...mentoringProgramAliases,
        })}
        {targetElementRef
          && ReactDOM.createPortal(
            <div className='d-inline-block'>
              <NumberInput
                withForm
                name={name}
                min={MIN_MENTEES}
                max={MAX_MENTEES}
              />
            </div>,
            targetElementRef,
          )}
      </div>
    );
  },
);

export default MaxMenteesSetting;
