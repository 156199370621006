import moment from 'moment';
import t from 'react-translate';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DATE_FORMAT } from 'athena/components/constants';
import CollapsiblePanel from 'athena/components/collapsible-panel';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import {
  CompletionStatus,
  MentoringProgramEnrollment,
  StepKey,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import StepItem from './step-item';
import { Step } from './types';
import CompleteProfileButton from './profile-completion/complete-profile-button';
import { isAnyRequiredFieldUnanswered } from './utils';

const DAYS_BEFORE_MATCH = 5;

type StepListProps = {
  showPlanSessionFlyout: () => void,
};

const StepList = ({
  showPlanSessionFlyout,
}: StepListProps) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { matchAnnouncementDate, mentorshipProgramEnrollment: enrollmentId } = mentoringProgram || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(
    state => getMentoringProgramEnrollment(state, enrollmentId),
  );
  const { states } = mentorshipProgramEnrollment || {};
  const completionDate = matchAnnouncementDate
    ? moment(matchAnnouncementDate)
      .subtract(DAYS_BEFORE_MATCH, 'days')
      .format(DATE_FORMAT)
    : null;

  const formattedMatchAnnouncementDate = matchAnnouncementDate
    ? moment(matchAnnouncementDate).format(DATE_FORMAT)
    : null;
  const getContentBasedOnMatchAnnouncementDate = () => (moment(matchAnnouncementDate).isAfter(moment())
    ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.CONTENT_MATCH_AFTER_TODAY(
      formattedMatchAnnouncementDate,
    )
    : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.CONTENT_MATCH_BEFORE_TODAY(
      formattedMatchAnnouncementDate,
    ));

  const stepsList: { [key in StepKey]?: Step } = {
    [StepKey.PROFILE_COMPLETION]: {
      title: isAnyRequiredFieldUnanswered(states)
        ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.COMPLETE_YOUR_PROFILE.TITLE()
        : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.REVIEW_YOUR_PROFILE.TITLE(),
      content: completionDate
        ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.COMPLETE_YOUR_PROFILE.CONTENT(
          completionDate,
        )
        : null,
      showBadge: true,
      ctaButton: (
        <CompleteProfileButton
          status={
            states[StepKey.PROFILE_COMPLETION]?.status
            || CompletionStatus.NOT_STARTED
          }
        />
      ),
    },
    [StepKey.WAITING_CONNECTION]: {
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.TITLE(),
      content: matchAnnouncementDate
        ? getContentBasedOnMatchAnnouncementDate()
        : null,
    },
    [StepKey.WAITING_FIRST_SESSION]: {
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.TITLE(),
      content: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.CONTENT(),
      // ctaButton: (
      //   <Button onClick={showPlanSessionFlyout}>
      //     {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.ADD_SESSION()}
      //   </Button>
      // ),
    },
  };

  return (
    <CollapsiblePanel
      title={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.TITLE()}
    >
      <div className='mt-4'>
        {Object.entries(stepsList).map(
          ([key, { title, content, showBadge, ctaButton }]) => (
            <StepItem
              key={key}
              status={states[key]?.status || CompletionStatus.NOT_STARTED}
              title={title}
              content={content}
              showBadge={showBadge}
              ctaButton={ctaButton}
            />
          ),
        )}
      </div>
    </CollapsiblePanel>
  );
};

export default StepList;
