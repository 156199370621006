import prodPathReplace from 'shared/prod-path-rewrite';

import { User } from 'redux/schemas/models/my-account';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';

import NvIcon from 'shared/components/nv-icon';
import AvatarPill from './avatar-pill';

const BACKGROUND_IMAGE_HEIGHT = 120;

type MainInfoType = {
  key?: string;
  text?: string | React.ReactNode;
  className?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
};

const getSection = ({
  text,
  className,
  prepend = null,
  append = null,
}: MainInfoType) => (
  text ? (
    <div className={className}>{prepend}{text}{append}</div>
  ) : null
);

const mainInfo: MainInfoType[] = [
  { key: 'fullName', className: 'heading-4 mb-2' },
  {
    key: 'location',
    className: 'text-small mb-2 d-flex justify-content-center',
    prepend: <NvIcon className='warning mr-2' icon='location' size='smallest' />,
  },
  { key: 'headline', className: 'heading-6 mb-1 bold' },
  { key: 'bio', className: 'gray-1' },
];

type MainDetailsType = {
  // For testing purposes, we are adding optional properties here.
  user: User & {
    bio?: string,
    headline?: string,
    location?: string,
  };
  role: MentoringProgramRole;
};

const MainDetails = ({ user, role }: MainDetailsType) => (
  <div className='d-flex flex-column align-items-center'>
    <img
      src={prodPathReplace('images/mentee-bg.png')}
      alt='profile'
      height={BACKGROUND_IMAGE_HEIGHT}
    />
    <div className='px-5 pb-4 info-container'>
      <AvatarPill user={user} role={role} />
      <div className='text-center pt-2'>
        {mainInfo.map((item) => (
          getSection({ ...item, text: user[item.key] })
        ))}
      </div>
    </div>
  </div>
);

export default MainDetails;
