import t from 'react-translate';
import {
  AccountField,
  getName,
} from 'athena/components/mentoring-program/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import AthenaTextInput from 'athena/components/text-input';
import { InputType } from 'redux/schemas/models/mentoring-program-profile-questions';
import { getCurrentUser } from 'redux/selectors/users';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { Institution } from 'redux/schemas/models/institution';
import { useFormContext } from 'react-hook-form';
import { User } from 'redux/schemas/models/my-account';
import Question from '../question';

type AccountQuestionsProps = {
  questions: MentorshipProgramProfileAnswer[];
};
const AccountQuestions = ({ questions }: AccountQuestionsProps) => {
  const user: User = useSelector(getCurrentUser);
  const currentInstitution: Institution = useSelector(getCurrentInstitution);
  const { enableBasicSsoInfoEdit = true } = currentInstitution;
  const { setValue } = useFormContext();

  const findAccountLevelItem = (name: string) => Object.values(questions || {}).find(
    (item: MentorshipProgramProfileAnswer) => {
      const { novoedQuestionName } = item;
      return novoedQuestionName === name;
    },
  ) as MentorshipProgramProfileAnswer;

  const accountLevelFields = useMemo(() => {
    const profilePicture = findAccountLevelItem(AccountField.PICTURE_FILE_NAME);
    const { firstName, lastName } = user;
    const name = [
      {
        label: 'firstName',
        value: firstName,
        placeholder: t.PROFILE.FIRST_NAME(),
      },
      {
        label: 'lastName',
        value: lastName,
        placeholder: t.PROFILE.LAST_NAME(),
      },
    ];
    const accountFields = [
      {
        field: AccountField.HEADLINE,
        type: InputType.SHORT_TEXT,
      },
      {
        field: AccountField.BIO,
        type: InputType.SHORT_TEXT,
      },
      {
        field: AccountField.DISPLAY_LOCATION,
        type: InputType.DISPLAY_LOCATION,
      },
    ];

    return (
      <div className='d-flex flex-column mb-4 account-level-fields'>
        {profilePicture && (
          <Question
            id={profilePicture.id}
            key={profilePicture.id}
            type={InputType.IMAGE}
            answer={profilePicture.answer}
            isRequired={profilePicture.isRequired}
            name={profilePicture.novoedQuestionName}
            title={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.ORG_QUESTIONS.ACCOUNT_FIELDS.PROFILE_PHOTO.TITLE()}
          />
        )}
        {firstName && lastName && (
          <div className='d-flex name'>
            {name.map(({ label, value, placeholder }) => (
              <AthenaTextInput
                key={label}
                disabled={!enableBasicSsoInfoEdit}
                name={label}
                className='w-100'
                value={value}
                placeholder={placeholder}
                onChange={({ target: { value: inputValue } }) => setValue(label, inputValue)}
              />
            ))}
          </div>
        )}
        {accountFields.map((item) => {
          const field = findAccountLevelItem(item.field);
          if (field) {
            const { id, novoedQuestionName, answer, isRequired } = field;
            if (novoedQuestionName) {
              return (
                <Question
                  id={id}
                  key={id}
                  answer={answer}
                  isRequired={isRequired}
                  name={novoedQuestionName}
                  type={item.type}
                  title={getName(novoedQuestionName, t)}
                />
              );
            }
          }
          return null;
        })}
      </div>
    );
  }, [findAccountLevelItem, questions, user]);

  return accountLevelFields;
};

export default AccountQuestions;
