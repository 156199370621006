import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import MentoringProgramContext from 'athena/components/mentoring-program/context';

import { css } from '@emotion/react';
import { novoEdBlack } from 'athena/styles/colors';
import { white } from 'styles/global_defaults/colors';

import { MentorshipProgramConnectionMember } from 'redux/schemas/models/mentorship-program-connections';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import ConnectionInfo from './connection-info';
import NoConnection from './no-connection';

const ConnectionDetails = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { mentorshipProgramEnrollment: enrollmentId, programAlias } = mentoringProgram || {};
  const mentorshipProgramEnrollment = useSelector(state => getMentoringProgramEnrollment(state, enrollmentId));
  const { assignedConnection } = mentorshipProgramEnrollment || {};

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const connectionId = parseInt($state.params.connectionId, 10);
  const connection = assignedConnection.find((connec) => connec.id === connectionId) ?? assignedConnection[0];

  const styles = css`
    min-height: 300px;
    height: ${connection ? 'fit-content' : '300px'};
    border-radius: 10px;
    background-color: ${white};
    .title {
      color: ${novoEdBlack};
    }
  `;

  return (
    <div css={styles} className='d-flex flex-column right-panel'>
      {connection ? (
        <ConnectionInfo user={connection.user} role={connection.role} />
      ) : (
        <NoConnection />
      )}
    </div>
  );
};

export default ConnectionDetails;
