// Froala
import NvFroala from 'froala/components/nv-froala';
import { SanitizationLevel } from 'froala/helpers/sanitizer';
import { FroalaViewMode, NvFroalaProps, UploadType } from 'froala/helpers/nv-froala-constants';

// Styles
import { css } from '@emotion/react';
import { white } from 'styles/global_defaults/colors';
import { dividerMedium, novoEdBlue } from 'athena/styles/colors';
import { omit } from 'underscore';
import { getSanitizedStyles } from 'shared/utils';

export enum ResizeType {
  NONE = '',
  BOTH = 'both',
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

const FROALA_MAX_HEIGHT = 900;

const defaultConfig = {
  withForm: true,
  allowToolbar: true,
  className: 'w-100',
  uploadType: UploadType.NONE,
  preset: FroalaViewMode.NORMAL,
  sanitizationLevel: SanitizationLevel.BASIC,
};

const styles = css`
  .fr-wrapper {
    border: 1px solid ${dividerMedium};
    border-radius: 4px;
    box-shadow: none;
    background-color: transparent;
  }

  .fr-element {
    background-color: transparent;
    resize: vertical;

    &:focus {
      outline: 2px solid ${novoEdBlue} !important;
      outline-offset: -2px !important;
      box-shadow: none;
      border-radius: 4px;
    }
  }

  .fr-placeholder {
    background-color: ${white} !important;
    border: none !important;
  }
`;

type NvAthenaFroalaProps = NvFroalaProps & {
  containerClassName?: string;
  maxHeight?: number;
  resize?: ResizeType;
};

const NvAthenaFroala = (props: NvAthenaFroalaProps) => {
  const froalaStyles = css`
    .fr-element {
      resize: ${props.resize || ResizeType.VERTICAL};
      max-height: ${props.maxHeight || FROALA_MAX_HEIGHT}px;
    }
  `;

  const froalaConfig = {
    css: getSanitizedStyles([styles, froalaStyles]),
    ...defaultConfig,
    ...(omit(props, 'containerClassName', 'maxHeight', 'resize')),
  };

  return (
    <div className={props.containerClassName}>
      <NvFroala {...froalaConfig} />
    </div>
  );
};

export default NvAthenaFroala;
