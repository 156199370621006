import PusherService from 'shared/services/pusher-service';
import PusherChannelNames from 'shared/services/pusher-channel-names';
import { AppDispatch } from 'redux/store';
import humps from 'humps';
import {
  setAutoMatchingLastUpdated,
  setAutoMatchingStatus,
  setConnectionsCount,
} from 'redux/actions/mentoring-programs';
import { AutoMatchingStatus } from 'redux/schemas/app/mentoring-programs';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import t from 'react-translate';
import { refreshProgramParticipantsView } from 'redux/actions/mentoring-program-enrollments';
import { AutoMatchingEvent, BulkEnrollmentEvent, Event, EventType } from './event-types';

const events = [
  {
    name: EventType.AUTO_MATCHING,
    handler: (event: Event, dispatch: AppDispatch) => {
      const {
        mentorshipProgramId,
        autoMatchingStatus,
        totalConnectionsCount,
        createdConnectionsCount,
        autoMatchingLastUpdated = new Date(),
        unmatchedCount = 0,
      } = event as AutoMatchingEvent;
      dispatch(setAutoMatchingStatus({ autoMatchingStatus }));
      dispatch(setConnectionsCount({ totalConnectionsCount }));
      if (autoMatchingStatus === AutoMatchingStatus.COMPLETED) {
        dispatch(setAutoMatchingLastUpdated({
          mentorshipProgramId,
          autoMatchingLastUpdated,
        }));
        let message = t.MENTORING_PROGRAMS.CONNECTIONS.SUCCESS_MESSAGE(
          createdConnectionsCount,
        );
        if (unmatchedCount) {
          if (createdConnectionsCount) {
            message = t.MENTORING_PROGRAMS.CONNECTIONS.SUCCESS_MESSAGE_CONNECTIONS_WITH_UNMATCHED(
              createdConnectionsCount,
              unmatchedCount,
            );
          } else {
            message = t.MENTORING_PROGRAMS.CONNECTIONS.SUCCESS_MESSAGE_NO_CONNECTIONS_WITH_UNMATCHED(
              unmatchedCount,
            );
          }
        }
        dispatch(
          addAlertMessage({
            header: t.FORM.SUCCESS_BANG(),
            type: AlertMessageType.SUCCESS,
            message,
          }),
        );
      }
    },
  },
  {
    name: EventType.BULK_ENROLLMENT,
    handler: (event: Event, dispatch: AppDispatch) => {
      const { mentorshipProgramId, newEnrolledCount, errors } = event as BulkEnrollmentEvent;
      if (!errors?.length && mentorshipProgramId) {
        if (newEnrolledCount) {
          dispatch(refreshProgramParticipantsView({ refreshView: true }));
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.FORM.SUCCESS(),
            message: t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NUM_PARTICIPANTS_ADDED(newEnrolledCount),
          }));
        } else {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.FORM.SUCCESS(),
            message: t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NO_PARTICIPANTS_ADDED(),
          }));
        }
      }
    },
  },
];

const setupEventsListener = (
  mentoringProgramId: number,
  dispatch: AppDispatch,
) => {
  const channelName = PusherChannelNames.mentoringProgramChannel(
    mentoringProgramId,
  );
  const pusherChannel = PusherService.setupChannel(channelName);

  events.forEach(({ name, handler }) => pusherChannel.bind(name, (event: Event) => {
    const camelizedEvent = humps.camelizeKeys(event) as Event;
    handler(camelizedEvent, dispatch);
  }));

  return () => {
    events.forEach(({ name, handler }) => pusherChannel.unbind(name, handler));
    PusherService.removeChannel(channelName);
  };
};

export default setupEventsListener;
