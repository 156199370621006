import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import NvRouter from 'shared/components/nv-router';
import Root from 'athena/components/mentoring-program/root';
import MyMenteesList from 'athena/components/mentoring-program/my-mentees-list';
import { getMentoringProgram } from 'redux/selectors/mentoring-programs';
import { useProgramSummaryCounts } from 'athena/hooks/use-program-summary-counts';
import MentoringProgramContext, { MentoringProgramError } from 'athena/components/mentoring-program/context';
import { getMentoringProgram as fetchMentoringProgram } from 'redux/actions/mentoring-programs';
import ProgramConfiguration from './program-configuration';

const contentRoutes = [
  {
    path: '/settings',
    component: ProgramConfiguration,
  },
  {
    path: '/my-mentees',
    component: MyMenteesList,
  },
  {
    path: '/',
    component: Root,
  },
];

const MentoringProgram = () => {
  const dispatch = useAppDispatch();
  const countState = useProgramSummaryCounts();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<MentoringProgramError>(null);
  const [programName, setProgramName] = React.useState('');
  const { injectServices } = React.useContext(AngularContext);
  const [$state, AlertMessages] = injectServices(['$state', 'AlertMessages']);
  const { programId } = $state.params;

  const routerBasename = `/#!/mentoring-programs/${programId}`;

  const mentoringProgram = useSelector((state: RootState) => getMentoringProgram(state, programId));

  const styles = css`
    height: ${error ? '100%' : '100vh'};

    .fr-toolbar {
      left: unset !important;
    }
  `;

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(fetchMentoringProgram(programId)).then((response) => {
      if (response.error) {
        const { message, mentorshipProgramName } = JSON.parse(response.error?.message) ?? {};
        if (message === MentoringProgramError.USER_DROPPED) {
          setError(MentoringProgramError.USER_DROPPED);
          setProgramName(mentorshipProgramName);
        } else {
          setError(MentoringProgramError.USER_NOT_AUTHORIZED);
          $state.go('dashboard').then(() => {
            AlertMessages.error('', 'COURSE_HOME.HEADER.COURSE_ACCESS_DENIED');
          });
        }
      }
      setIsLoading(false);
    });
  }, [dispatch, programId]);

  const contextValue = {
    isLoading,
    countState,
    mentoringProgram: mentoringProgram ?? { name: programName },
    error,
  };

  return (
    <MentoringProgramContext.Provider value={contextValue}>
      <div className='d-flex' css={styles}>
        <NvRouter routes={contentRoutes} basename={routerBasename} />
      </div>
    </MentoringProgramContext.Provider>
  );
};

export default MentoringProgram;
