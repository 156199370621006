import React, { Ref, useState, useRef, useEffect } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import t from 'react-translate';
import { isHandheld, notHandheld } from 'styles/global_defaults/media-queries';
import { primary } from 'styles/global_defaults/colors';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { setActiveOptionMenu } from 'redux/actions/optionsMenu';
import { Course } from 'redux/schemas/models/course';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle, NvDropdownAlign } from './inputs/nv-dropdown';
import { NvTooltip } from './nv-tooltip';
import { NvTableCellProps, BKG_ROW_Z_INDEX } from './nv-responsive-table';


type NvResponsiveTableOptionsCellProps<T> = T & {
  optionsCellRef: Ref<HTMLDivElement>,
  optionItems: NvDropdownOption[],
  dataQa?: string,
  // TODO: This is actually required for proper function. Investigate.
  dataQaId?: string,
  onToggle?: (isOpen: boolean, event?: any, metadata?: any) => void,
  course?: Course,
  iconSize?: string,
};

const styles = css`
  /* Override the padding for each cell in nv-responsive-table */
  padding-right: 0 !important;
  grid-column: 6;
  width: 100%;
  text-align: center;

  &:not(.disabled) {
    z-index: ${BKG_ROW_Z_INDEX + 1} !important;
  }

  &.active {
    /* This must be higher than the other cell's z-index in order for the dropdown to be clickable */
    z-index: 100 !important;
  }

  & > *, & .bs4-dropdown, & .bs4-dropdown > * {
    width: 100%;
    height: 100%;
    user-select: none;
  }

  .options-btn {
    width: 100%;
    display: flex;
    align-items: center;
  }

  /* Size the ellipses icons containers to place the ellipses in the center of the cell.
  .dropleft and its child container are added in mobile resolution */
  .options-btn, .dropleft, .dropup, .dropleft > div:first-of-type, .dropup > div:first-of-type, .dropdown, .dropdown > div:first-of-type {
    height: 100%;
  }

  .icon {
    /* Hide by default on non-mobile */
    ${notHandheld(css`
      display: none;
    `)};
    margin-left: auto;
    margin-right: auto;
  }

  &:hover:not(.disabled), &:active:not(.disabled) {
    background-color: ${primary} !important;
    .icon {
      display: block !important;
      color: white !important;
    }
  }

  .bs4-dropdown-menu.show {
    display: table !important;
    height: unset;
  }
`;

const NvResponsiveTableOptionsCell = <S extends NvTableCellProps<T, U, V>, T, U = {}, V = {}>(props: NvResponsiveTableOptionsCellProps<S>) => {
  const currentMenuOptionsCell = useSelector((state) => state.app.optionsMenu?.menuItem);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useAppDispatch();
  const onToggle = (isOpen: boolean = false, event?: any,
    metadata?: any) => {
    // There is a bug in IE11 and Edge that causes a second onToggle event to fire here when closing the dropdown. This second
    // event has an event of 'false' and source of 'undefined'. Ignoring this causes the dropdown to close normally, and forgetting to do this causes the
    // dropdown to mistakenly re-open
    if (isOpen && event === false && metadata.source === undefined) {
      return;
    }

    if (isOpen) {
      dispatch(setActiveOptionMenu({ menuItem: props.dataQaId, catalogId: props.course?.catalogId }));
    } else {
      dispatch(setActiveOptionMenu({ menuItem: null, catalogId: props.course?.catalogId }));
    }
    if (props.onToggle) {
      props.onToggle(isOpen, event, metadata);
    }
  };

  useEffect(() => {
    if (props.dataQaId) {
      const isOpen = currentMenuOptionsCell === props.dataQaId;
      setIsActive(isOpen);
    }
  }, [currentMenuOptionsCell, props.dataQaId]);

  return (
    <div
      className={`options-cell ${isActive && 'active'} ${props.rowClasses ?? ''}`}
      ref={props.optionsCellRef}
      css={css`${styles} ${props.serializedStyles}`}
      // key={props.reactKey}
      {...props.divProps}
    >
      {!props.disabled
      && (
        <React.Fragment>
          <NvDropdown
            buttonStyle={NvDropdownButtonStyle.CUSTOM}
            items={props.optionItems}
            customTarget={() => (
              <NvTooltip text={t.TIMELINE.MORE_OPTIONS()} enabled={!props.disabled} preventOverflow>
                <div className='options-btn' data-qa={props.dataQa} data-qa-id={props.dataQaId}>
                  <div className={`icon icon-${props.iconSize ?? 'medium'} icon-more`} />
                </div>
              </NvTooltip>
            )}
            onToggle={onToggle}
            show={isActive}
            drop='left'
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default NvResponsiveTableOptionsCell;
