import React, { useCallback } from 'react';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import t from 'react-translate';
import moment from 'moment';
import useMentoringProgramRole from 'athena/hooks/use-mentoring-program-role';

// Styles
import { css } from '@emotion/core';
import { hexToRgbaString } from 'styles/global_defaults/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { screenMdMin } from 'styles/global_defaults/media-queries';
import { almostBlack, novoEdBlue, oasis } from 'athena/styles/colors';
import { quarterSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import {
  ReactComponent as MentoringProgramHeaderPicture,
} from 'styles/icons/mentoring-program-header-picture.svg';

// Components
import NvIcon from 'shared/components/nv-icon';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import AutoMatchingMenu from './program-header-menu/auto-matching-menu';
import NvTooltip from 'shared/components/nv-tooltip';

import { PROGRAM_DATES_FORMAT, PROGRAM_DATES_FORMAT_WITH_TIME } from './constants';

const hasBeenReleased = (date) => date && moment(date).isBefore(moment());
const hasBeenClosed = (date) => date && moment().isAfter(date);
const getFormattedDate = (date: string, withTime: boolean = false) => (
  moment(date).format(withTime ? PROGRAM_DATES_FORMAT_WITH_TIME : PROGRAM_DATES_FORMAT)
);

const getTooltipText = (releasedDate, endDate) => {
  const released = hasBeenReleased(releasedDate);
  const closed = hasBeenClosed(endDate);
  const translationBase = t.MENTORING_PROGRAMS.PROGRAM_START_DATE.TOOLTIP;
  const tooltipDates = endDate
    ? [getFormattedDate(endDate, true), getFormattedDate(releasedDate, true)]
    : [getFormattedDate(releasedDate, true)];

  let tooltipKey;
  if (closed) {
    tooltipKey = 'CLOSED_PROGRAM';
  } else if (released && endDate) {
    tooltipKey = 'STARTED_AND_HAS_END_DATE';
  } else if (released && !endDate) {
    tooltipKey = 'STARTED_AND_HAS_NO_END_DATE';
  } else if (!released && endDate) {
    tooltipKey = 'NOT_STARTED_AND_HAS_END_DATE';
  } else {
    tooltipKey = 'NOT_STARTED_AND_HAS_NO_END_DATE';
  }

  return translationBase[tooltipKey](...tooltipDates);
};

const getProgramDatesText = (releasedDate, endDate) => {
  const released = hasBeenReleased(releasedDate);

  const getReleaseText = () => {
    const labelKey = released ? 'STARTED' : 'NOT_STARTED';
    return t.MENTORING_PROGRAMS.PROGRAM_START_DATE[labelKey](
      getFormattedDate(releasedDate),
    );
  };

  return endDate ? t.MENTORING_PROGRAMS.PROGRAM_RANGE(
    getFormattedDate(endDate),
    getFormattedDate(releasedDate),
  ) : getReleaseText();
}

const getProgramDatesComponent = (releasedDate, endDate) => {
  const programDateText = getProgramDatesText(releasedDate, endDate);
  const tooltipText = getTooltipText(releasedDate, endDate);

  return (
    <NvTooltip enabled text={tooltipText} placement='right'>
      <div>{programDateText}</div>
    </NvTooltip>
  );
}

type Props = {
  scrollPercentage: number;
};

const ProgramHeader = (props: Props) => {
  const { scrollPercentage } = props;
  const { isLoading, mentoringProgram } = React.useContext(MentoringProgramContext);
  const { isAdmin } = useMentoringProgramRole();

  const isTablet = useMediaQuery({
    query: `(max-width: ${screenMdMin}px)`,
  });

  const isMainEnabled = scrollPercentage <= 50;

  const styles = css`
    height: 350px;
    background-color: ${hexToRgbaString(oasis, 0.1)};
    border-bottom: ${quarterSpacing}px solid ${oasis};

    .top-bar {
      height: ${tripleSpacing}px;

      .date-container {
        flex: 1;
        opacity: 0.8;
        color: ${almostBlack};
      }
    }

    .main {
      .main-description {
        opacity: 0.8;
        color: ${almostBlack};
      }
    }

    .main, .top-bar {
      opacity: ${1 - ((1 / 100) * scrollPercentage)};
    }

    .secondary {
      bottom: ${tripleSpacing}px;
      opacity: ${(1 / 100) * scrollPercentage};

      .secondary-name {
        flex: 1;
      }
    }

    .mentoring-program-picture {
      bottom: 80px;
      right: ${isTablet ? -standardSpacing : 70}px;
    }

    .menu-items {
      gap: ${standardSpacing}px;
    }
    .link {
      color: ${novoEdBlue};
      font-weight: ${semiBoldFontWeight};
    }
  `;

  const renderSettingsButton = (layoutOnly: boolean) => {
    const icon = <NvIcon icon='settings' size='smallest' />;

    if (layoutOnly) {
      return (
        <div>
          {icon}
        </div>
      );
    }

    return (
      <Link to='/settings'>
        {icon}
      </Link>
    );
  };

  const renderProgramDates = useCallback(() => (
    (mentoringProgram?.releasedDate || mentoringProgram?.endDate)
      ? getProgramDatesComponent(mentoringProgram?.releasedDate, mentoringProgram?.endDate)
      : (
        <Link to='/settings?tab=program' className='link bold text-large-body'>
          {t.MENTORING_PROGRAMS.ADD_PROGRAM_DATES()}
        </Link>
      )
  ), [mentoringProgram?.releasedDate, mentoringProgram?.endDate]);

  return (
    <div css={styles} className='pl-4 pr-4'>
      <div className='position-relative h-100'>
        <div className='d-flex top-bar'>
          {isAdmin && (
            <>
              <div className='d-flex align-items-end date-container text-body'>
                {renderProgramDates()}
              </div>
              <div className='d-flex align-items-center menu-items pt-4'>
                <AutoMatchingMenu />
                {renderSettingsButton(!isMainEnabled)}
              </div>
            </>
          )}
        </div>
        {!isLoading && (
          <>
            <div className='main'>
              <div className='w-50'>
                <Truncate
                  lines={1}
                  ellipsis='...'
                  className='heading-1'
                >
                  {mentoringProgram?.name}
                </Truncate>
              </div>
              <div className='w-50'>
                <Truncate
                  lines={3}
                  ellipsis='...'
                  className='text-large-body main-description'
                >
                  {mentoringProgram?.description}
                </Truncate>
              </div>
              <MentoringProgramHeaderPicture
                width={432}
                height={266.4}
                className='mentoring-program-picture position-absolute'
              />
            </div>
            <div className='secondary position-absolute w-100 d-flex align-items-center'>
              <div className='secondary-name'>
                <Truncate
                  lines={1}
                  ellipsis='...'
                  className='heading-2'
                >
                  {mentoringProgram?.name}
                </Truncate>
              </div>
              {isAdmin && (
              <div className='d-flex align-items-center menu-items pt-4'>
                <AutoMatchingMenu />
                {renderSettingsButton(isMainEnabled)}
              </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProgramHeader;
