/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile-questions';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { useAppDispatch } from 'redux/store';
import {
  getMentorshipProgramProfileAnswers,
} from 'redux/actions/mentoring-program-profile-answers';
import { getMentorshipProgramProfileAnswersList } from 'redux/selectors/mentorship-program-profile-answers';
import {
  PAGE_SIZE,
} from 'athena/components/mentoring-program/constants';
import { GetProgramProfileQuestionParams } from '../components/mentoring-program/program-configuration/program-profile-setup/types';

type UseMentoringProgramQuestionsAnswers = {
  type: ProgramProfileQuestionType;
  mentorshipProgramId: number;
  enrollmentId: number;
};

export const useMentoringProgramQuestionsAnswers = ({
  type,
  mentorshipProgramId,
  enrollmentId,
}: UseMentoringProgramQuestionsAnswers) => {
  const dispatch = useAppDispatch();

  const params = useMemo(
    () => ({
      type,
      pageSize: PAGE_SIZE,
    }),
    [type],
  );

  const { result: retrievedQuestions, loadMore } = usePaginatedApi<
  MentorshipProgramProfileAnswer,
  GetProgramProfileQuestionParams
  >(
    p => dispatch(
      getMentorshipProgramProfileAnswers({
        mentorshipProgramId,
        mentorshipProgramEnrollmentId: enrollmentId,
        ...p,
      }),
    ).then(action => action.payload),
    params,
    getMentorshipProgramProfileAnswersList,
  );

  return {
    loadMore,
    questions: retrievedQuestions,
  };
};
