import t from 'react-translate';
import { css } from '@emotion/react';
import { darkGray, dividerMedium } from 'athena/styles/colors';
import {
  InputType,
  ProgramLevelQuestionType,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import { textMediumFontSize } from 'styles/global_defaults/fonts';
import AthenaTextInput from 'athena/components/text-input';
import AthenaTextarea from 'athena/components/textarea';
import NvSelect from 'shared/components/inputs/nv-select';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import NvGooglePlacesAutocomplete from 'components/nv-google-places-autocomplete';
import { danger } from 'styles/global_defaults/colors';
import MultiSelectCheckList from './multi-select-checklist';
import ProfilePhotoField from './profile-photo-field';
import DropdownQuestion from './dropdown-question';

const styles = css`
  .input-label {
    color: ${darkGray};
    font-size: ${textMediumFontSize}px;
  }
  .select-dropdown {
    div {
      width: 626px;
      .text-medium {
        border: 1px solid ${dividerMedium};
      }
    }
    .select-menu {
      div {
        width: 620px;
      }
    }
  }
  .required {
    .input-label::after {
      content: "*";
      color: ${danger};
    }
  }
`;

type QuestionProps = {
  id: number;
  type: InputType | ProgramLevelQuestionType;
  title: string;
  answer: string | string[] | null;
  name?: string;
  options?: string[];
  isRequired?: boolean;
};

const Question = ({
  id,
  type,
  title,
  answer,
  name,
  options,
  isRequired,
}: QuestionProps) => {
  const { setValue } = useFormContext();

  const initializeFormState = (value) => {
    setValue(
      `${id}`,
      {
        name,
        isRequired,
        mentorshipProgramProfileQuestionId: id,
        answer: value,
      },
      { shouldValidate: true },
    );
  };

  useEffect(() => {
    initializeFormState(answer);
  }, []);

  const renderQuestion = () => {
    let question = null;
    switch (true) {
      case type === InputType.IMAGE:
        question = (
          <ProfilePhotoField
            title={title}
            onChange={(value) => initializeFormState(value)}
          />
        );
        break;
      case type === InputType.SHORT_TEXT
        || type === ProgramLevelQuestionType.SHORT_TEXT:
        question = (
          <AthenaTextInput
            label={title}
            name={name || title}
            className={`w-100 ${isRequired ? 'required' : ''}`}
            value={answer}
            onChange={({ target: { value } }) => initializeFormState(value)}
          />
        );
        break;
      case type === InputType.LONG_TEXT
        || type === ProgramLevelQuestionType.LONG_TEXT:
        question = (
          <AthenaTextarea
            label={title}
            name={name || title}
            className={isRequired ? 'required' : ''}
            value={answer as string}
            onChange={({ target: { value } }) => initializeFormState(value)}
          />
        );
        break;
      case type === InputType.DROPDOWN
        || type === ProgramLevelQuestionType.DROPDOWN:
        {
          const dropdownOptions = options.map((option) => ({
            label: option,
            value: option,
          }));
          question = (
            <DropdownQuestion
              title={title}
              answer={answer as string}
              options={dropdownOptions}
              className={isRequired ? 'required' : ''}
              onChange={(value) => initializeFormState(value)}
              placeholder={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.PLACEHOLDERS.DROPDOWN()}
            />
          );
        }
        break;
      case type === InputType.CHECKLIST
        || type === ProgramLevelQuestionType.CHECKLIST:
        {
          const multiSelectOptions = options.map((option) => ({
            label: option,
            value: option,
          }));
          question = (
            <MultiSelectCheckList
              label={title}
              options={multiSelectOptions}
              className={isRequired ? 'required' : ''}
              onChange={(value) => initializeFormState(value)}
              selectedOptions={answer as string[]}
            />
          );
        }
        break;
      case type === InputType.DISPLAY_LOCATION:
        question = (
          <div className={isRequired ? 'required' : ''}>
            <div className='input-label text-regular semi-bold mb-1'>
              {title}
            </div>
            <NvGooglePlacesAutocomplete
              value={answer as string}
              onChange={(value) => initializeFormState(value)}
            />
          </div>
        );
        break;
      default:
        break;
    }
    return question;
  };

  return <div css={styles}>{renderQuestion()}</div>;
};

export default Question;
