import React from 'react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { getCurrentUser } from 'redux/selectors/users';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import { enrollUserToProgram } from 'redux/actions/mentoring-program-enrollments';
import ManageOfferingAccessModal from 'institutions/components/manage-offering-access-modal';

type Props = {
  programId?: number;
  onClose?: () => void;
};

const ManageProgramAccessModal = (props: Props) => {
  const { onClose, programId } = props;
  const dispatch = useAppDispatch();

  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const currentUser = useSelector(getCurrentUser);

  const handleEnroll = (roleValue) => wrapThunkAction(dispatch(enrollUserToProgram({
    programId,
    email: currentUser.email,
    role: MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN,
  }))).then(() => {
    onClose();
    $state.go('mentoring-program-root', {
      programId,
    });
  });

  return (
    <ManageOfferingAccessModal
      type='program'
      onClose={onClose}
      show={!!programId}
      selectedRoleValue={-1}
      onEnroll={handleEnroll}
      roles={[{
        value: -1,
        label: t.MENTORING_PROGRAMS.PROGRAM_ADMIN(),
      }]}
      managementLink={$state.href('mentoring-program-participant-management', {
        id: programId,
      })}
    />
  );
};

export default ManageProgramAccessModal;
