import CollapsiblePanel from 'athena/components/collapsible-panel';
import t from 'react-translate';

const LABELS = {
  department: 'DEPARTMENT',
  topicsInterested: 'TOPICS_INTERESTED',
};

const getField = (text: string | string[], label: string) => (
  <div className='mt-4'>
    <div className='text-small gray-2 mb-1'>{label}</div>
    <div className='text-regular heading-5 mb-2'>
      {Array.isArray(text) ? text.join(', ') : text}
    </div>
  </div>
);

const ProgramProfile = (props) => {
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.CONNECTION_DETAILS.PROGRAM_PROFILE;
  const keys = Object.keys(props);

  return (
    <div className='px-5 py-4 info-container'>
      <CollapsiblePanel title='PROGRAM PROFILE' iconSize='smallest'>
        <div className='mt-4'>
          {keys.map((name) => (
            getField(props[name], LABELS[name] ? translationBase[LABELS[name]]() : name)
          ))}
        </div>
      </CollapsiblePanel>
    </div>
  );
};

export default ProgramProfile;
