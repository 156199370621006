import { useContext } from 'react';
import t from 'react-translate';
import CollapsiblePanel from 'athena/components/collapsible-panel';
import NvFroala from 'froala/components/nv-froala';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import { css } from '@emotion/react';
import MentoringProgramContext from '../../context';

const styles = css`
  .fr-wrapper {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    cursor: default !important;
  }
  .fr-element {
    background-color: transparent !important;
    padding: 0 !important;
  }
`;

const ProgramGuidelinesAndExpectations = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { guideline } = mentoringProgram || {};

  return (
    guideline && (
      <CollapsiblePanel
        title={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.GUIDELINES_AND_EXPECTATIONS.TITLE()}
      >
        <div css={styles} className='mt-4'>
          <NvFroala
            value={guideline}
            preset={FroalaViewMode.AIR}
            isDisabled
          />
        </div>
      </CollapsiblePanel>
    )
  );
};
export default ProgramGuidelinesAndExpectations;
