import { User } from 'redux/schemas/models/my-account';
import { MentoringProgramEnrollmentRole } from 'redux/schemas/models/mentoring-program-enrollments';

import { css } from '@emotion/react';
import { gray6 } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';

import MainDetails from './main-details';
import MoreDetails from './more-details';
import ProgramProfile from './program-profile';

const styles = css`
  .avatar {
    margin-top: -70px;
  }
  .info-container {
    border: 1px solid ${gray6};
    .collapsible-panel {
      padding: 0;
      .title {
        font-size: ${textSmallFontSize}px;
      }
    }
  }
`;

type ConnectionInfoProps = {
  user: User;
  role: MentoringProgramEnrollmentRole;
};

const ConnectionInfo = ({
  user,
  role,
}: ConnectionInfoProps) => {
  const { name: connectionRole } = role || {};

  return (
    <div css={styles}>
      <MainDetails user={user} role={connectionRole} />
      <MoreDetails />
      <ProgramProfile />
    </div>
  );
};

export default ConnectionInfo;
