import { css } from '@emotion/react';
import { blue05, novoEdBlack, novoEdBlue } from 'athena/styles/colors';
import { useState } from 'react';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { gray7 } from 'styles/global_defaults/colors';
import {
  quarterSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';

const styles = css`
  .multi-select-option {
    height: ${tripleSpacing}px;
    background-color: ${gray7};
    border-radius: ${quarterSpacing}px;

    label {
      margin-bottom: 0;
      font-size: 16px;
      color: ${novoEdBlack};
    }
  }
  .checked {
    background-color: ${blue05};
    border: 1px solid ${novoEdBlue};
    label {
      color: ${novoEdBlue};
      :before {
        color: ${novoEdBlue} !important;
        border: 2px solid ${novoEdBlue} !important;
      }
    }
  }
`;

type Option = {
  label: string;
  value: string;
};

type MultiSelectCheckListProps = {
  label: string;
  options: Option[];
  onChange?: (event) => void;
  className?: string;
  selectedOptions?: string[];
};

const MultiSelectCheckList = ({
  label,
  options,
  className,
  onChange,
  selectedOptions,
}: MultiSelectCheckListProps) => {
  const [selected, setSelected] = useState<string[]>(selectedOptions || []);

  const handleChange = (value: string, checked: boolean) => {
    const updatedSelection = checked
      ? [...selected, value]
      : selected.filter(option => option !== value);
    setSelected(updatedSelection);
    onChange(updatedSelection);
  };

  return (
    <div css={styles} className={className}>
      <div className='input-label text-regular semi-bold mb-4'>{label}</div>
      {options.map(
        ({ label: optionLabel, value: optionValue }) => {
          const isChecked = selected?.includes(optionValue);
          return (
            <NvCheckbox
              key={optionValue}
              name={optionLabel}
              value={optionValue}
              label={optionLabel}
              checked={isChecked}
              className={`multi-select-option mb-2 d-flex align-items-center p-3 ${isChecked ? 'checked' : ''}`}
              onChange={({ target: { value, checked } }) => handleChange(value, checked)}
            />
          );
        },
      )}
    </div>
  );
};

export default MultiSelectCheckList;
