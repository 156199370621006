import { css } from '@emotion/react';
import { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import NvAthenaFroala, { ResizeType } from 'athena/components/nv-athena-froala';
import AthenaTextInput from 'athena/components/text-input';
import ConfirmationModal from 'athena/components/mentoring-program/modals/confirmation-modal';
import NvFlyoutModal, { ModalType } from 'shared/components/nv-flyout-modal';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import { gray1, gray5, gray6 } from 'styles/global_defaults/colors';
import {
  semiBoldFontWeight,
  textLargeFontSize,
  textLargeBodyFontSize,
  textMediumLineHeight,
} from 'styles/global_defaults/fonts';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';


const FLYOUT_MODAL_WIDTH = 640;

type PlanSessionFlyoutModalProps = {
  onClose: () => void;
};

const PlanSessionFlyoutModal = ({
  onClose,
}: PlanSessionFlyoutModalProps) => {
  const styles = css`
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      padding: ${standardSpacing}px ${largeSpacing}px;
      font-weight: ${semiBoldFontWeight};
      font-size: ${textLargeFontSize}px;
      line-height: ${textMediumLineHeight}px;
      border-bottom: 1px solid ${gray5};
    }

    .content {
      flex: 1;
      padding: ${standardSpacing}px ${largeSpacing}px;

      .title {
        margin-bottom: ${halfSpacing}px;
      }

      .date-time {
        & > div, .react-datepicker-wrapper {
          width: 100%;
        }

        width: 362px;
        padding-bottom: ${threeQuartersSpacing}px;
      }

      .session-details {
        padding: ${threeQuartersSpacing}px ${quarterSpacing}px;
        font-weight: ${semiBoldFontWeight};
        font-size: ${textLargeBodyFontSize}px;
        line-height: ${textMediumLineHeight}px;
        border-top: 1px solid ${gray5};
      }
    }

    .button-row {
      padding: ${largeSpacing}px;
      text-align: right;
      border-top: 1px solid ${gray6};

      button {
        margin-left: ${threeQuartersSpacing}px;
      }
    }
  `;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  return (
    <Fragment>
      <NvFlyoutModal
        type={ModalType.RIGHT}
        width={FLYOUT_MODAL_WIDTH}
        onClose={onClose}
      >
        <div css={styles}>
          <div className='header'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.HEADER()}</div>
          <div className='content'>
            <div className='title'>
              <AthenaTextInput
                showLabel={false}
                placeholder={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.DEFAULT_TITLE('John', 'Susan')}
              />
            </div>
            <div className='date-time'>
              <NvDatePicker
                type={DatePickerType.DATETIME}
                placeholder={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SET_DATE_TIME()}
              />
            </div>
            <div className='session-details'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SESSION_DETAILS()}</div>
            <NvAthenaFroala
              name='description'
              withForm={false}
              resize={ResizeType.VERTICAL}
              placeholder={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SESSION_DETAILS_DESCRIPTION()}
            />
          </div>
          <div className='button-row'>
            <Button
              variant='secondary'
              onClick={() => setShowConfirmationModal(true)}
            >
              {t.FORM.CANCEL()}
            </Button>
            <Button>{t.FORM.ADD()}</Button>
          </div>
        </div>
      </NvFlyoutModal>
      <ConfirmationModal
        showModal={showConfirmationModal}
        header={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.CONFIRMATION_MODAL.HEADER()}
        content={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.CONFIRMATION_MODAL.BODY()}
        confirmButtonLabel={t.FORM.DISCARD()}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => {
          setShowConfirmationModal(false);
          onClose();
        }}
      />
    </Fragment>
  );
};

export default PlanSessionFlyoutModal;
